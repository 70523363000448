import { EditContactInfoRequest } from "models/contact";
import {
  Device,
  DeviceInfo,
  DeviceState,
  NetworkInfo,
  UpdateCarersRequest,
} from "models/device";
import { DeviceListItem } from "models/device-list-item";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";
import { TenantDevice } from "./tenants-api";
import { Page, SearchParams } from "models/search";

export const fetchDevices = (
  search: SearchParams,
  tenantID?: number
): Promise<Page<DeviceListItem>> =>
  fetch(`/api/tenant/${tenantID}/getdevices`, {
    method: "POST",
    body: JSON.stringify(search),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchAllTenantDevices = (
  search: SearchParams,
  tenantID?: number
): Promise<Page<DeviceListItem>> =>
  fetch(`/api/tenant/${tenantID}/getalldevices`, {
    method: "POST",
    body: JSON.stringify(search),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchDevice = (
  tenantId: number,
  deviceId: number
): Promise<Device> =>
  fetch("/api/tenant/" + tenantId + "/device/" + deviceId, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchDeviceInfo = (
  tenantId: number,
  deviceId: number
): Promise<DeviceInfo> =>
  fetch("/api/tenant/" + tenantId + "/device/" + deviceId + "/deviceinfo", {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchDeviceState = (
  tenantId: number,
  deviceId: number
): Promise<DeviceState> =>
  fetch("/api/tenant/" + tenantId + "/device/" + deviceId + "/state", {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchNetworkInfo = (
  tenantId: number,
  deviceId: number
): Promise<NetworkInfo> =>
  fetch("/api/tenant/" + tenantId + "/device/" + deviceId + "/networkinfo", {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const createDevice = (
  id: number,
  activated: number,
  productID: string,
  serialNumber: number,
  tenantId: number,
  model = "",
  variant = "",
  billingStartDate: Date,
  billingEndDate: Date,
  installationLocation = ""
): Promise<void> => {
  return fetch(`/api/tenant/${tenantId}/devices/add`, {
    method: "POST",
    body: JSON.stringify({
      id,
      activated,
      product_id: productID,
      serial_number: serialNumber,
      model: model?.length > 0 ? model : null,
      variant: variant?.length > 0 ? variant : null,
      billing_start_date:
        billingStartDate !== undefined ? billingStartDate : null,
      billing_end_date: billingEndDate !== undefined ? billingEndDate : null,
      installation_location:
        installationLocation?.length > 0 ? installationLocation : null,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
};

export const patchPhoneNumbers = (
  tenantId: number,
  deviceId: number,
  request: EditContactInfoRequest
): Promise<void> => {
  return fetch(`/api/tenant/${tenantId}/device/${deviceId}/phone-numbers`, {
    method: "POST",
    body: JSON.stringify({
      ...request,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
};

export const updateDevice = (tenantId: number, device: Device): Promise<void> =>
  fetch(`/api/tenant/${tenantId}/device/${device.id}`, {
    method: "PUT",
    body: JSON.stringify({
      id: device.id,
      sipSettings: device.sipSettings,
      simSettings: device.simSettings,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const updateCarers = (
  tenantId: number,
  deviceId: number,
  updateCarersRequest: UpdateCarersRequest
): Promise<void> =>
  fetch(`/api/tenant/${tenantId}/device/${deviceId}/carers`, {
    method: "PUT",
    body: JSON.stringify({
      carers: updateCarersRequest.carers,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const factoryResetDevice = (tenantLift: TenantDevice): Promise<void> =>
  fetch(
    `/api/tenant/${tenantLift.tenantID}/device/${tenantLift.deviceID}/reset`,
    {
      method: "PUT",
    }
  )
    .then(parseResponse)
    .catch((e) => handleError(e));
