import { useTranslation } from "react-i18next";
import { ContextMenu } from "../../components/ContextMenu";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { EditEmployee } from "./EditEmployee";
import { isRoleForAction } from "../../hooks/useRoles";
import { EmployeeListEditAllowedRoles } from "../../models/role";

export const EmployeeOptionsMenu = ({
  userID,
  setSelectedUserID,
  triggerUpdate,
}: {
  userID: number;
  triggerUpdate(): void;
  setSelectedUserID(selectedUserID: number): void;
}) => {
  const { t } = useTranslation();
  const isEditEmployeeAllowed: boolean = isRoleForAction(
    EmployeeListEditAllowedRoles
  );
  const { dispatchCliftState } = useCliftContext();

  const handleEditUser = async () => {
    if (userID !== null && userID !== undefined) {
      dispatchCliftState({
        type: CliftReducerAction.SetSidePanelContent,
        content: (
          <EditEmployee userId={userID} onEditCompleted={triggerUpdate} />
        ),
      });
    }
  };

  return (
    <ContextMenu
      items={[
        {
          id: "edit",
          title: t("edit"),
          hidden: !isEditEmployeeAllowed,
          onClicked: handleEditUser,
          icon: <EditIcon />,
        },
        {
          id: "delete",
          title: t("delete_employee", { ns: "employee" }),
          hidden: !isEditEmployeeAllowed,
          onClicked: () => setSelectedUserID(userID),
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
