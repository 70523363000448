import "./ToggleSwitch.css";

export interface ToggleSwitchProps {
  label?: string;
  on: boolean;
  onChange?: (on: boolean) => void;
}

export const ToggleSwitch = ({ label, on, onChange }: ToggleSwitchProps) => {
  return (
    <>
      <div className="toggle-switch-container">
        {label && <span>{label}</span>}
        <button
          title="toggle"
          type="button"
          disabled={!onChange}
          className={`toggle-switch-btn ${on ? "toggle-switch-on" : ""}`}
          onClick={() => {
            onChange?.(!on);
          }}
        >
          <div className="toggle-switch-thumb" />
        </button>
      </div>
    </>
  );
};
