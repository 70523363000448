import { useCallback } from "react";
import { downloadFile, fetchCsv } from "../services/csv-api";

interface CsvExportParams {
  tableName: string;
  tenantId: number;
}

/**
 * Hook for exporting table data as CSV.
 *
 * @returns {(params: CsvExportParams) => Promise<void>} A function to handle CSV export.
 */
export const useCsvExport = () => {
  /**
   * Handles CSV export for table data.
   *
   * @param {CsvExportParams} params - Parameters for CSV export.
   */
  const handleExport = useCallback(
    async ({ tableName, tenantId }: CsvExportParams): Promise<void> => {
      try {
        // Fetch CSV Blob from the backend
        const csvBlob = await fetchCsv(tableName, tenantId);

        // Trigger browser download
        const filename = `${tableName}-${new Date().toISOString()}.csv`;
        downloadFile(csvBlob, filename);
      } catch (error) {
        console.error("Error exporting CSV:", error);
      }
    },
    []
  );

  return handleExport;
};
