export const baseURL = "/api/tenant";

export async function fetchCsv(
  tableName: string,
  tenantId: number
): Promise<Blob> {
  try {
    const response = await fetch(`${baseURL}/${tenantId}/csv/${tableName}`, {
      method: "GET",
    });
    return response.blob(); // Return the Blob
  } catch (error) {
    console.error("Error fetching CSV table data:", error);
    throw error;
  }
}

export function downloadFile(blob: Blob, filename: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url); // Clean up URL object
}
