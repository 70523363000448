import i18n from "i18next";

export const enum CliftApiErrorCodes {
  ERR_PARSE = -1,
  ERR_DOM = -2,
  ERR_INVALID = -3,
  ERR_UNKNOWN = -4,
}

export class CliftApiError extends Error {
  public originalCause: unknown;
  public reason: string | undefined;
  public constructor(
    statusCode: number,
    originalCause?: unknown,
    reason?: string
  ) {
    super("Api error");
    this.originalCause = originalCause;
    this.cause = statusCode;
    this.reason = reason;
  }
}

export const handleError = (err: Error) => {
  if (err instanceof CliftApiError) {
    throw err;
  } else if (err instanceof SyntaxError) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_PARSE, err.cause);
  } else if (err instanceof DOMException) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_DOM, err.cause);
  } else if (err instanceof TypeError) {
    throw new CliftApiError(CliftApiErrorCodes.ERR_INVALID, err.cause);
  } else {
    throw new CliftApiError(CliftApiErrorCodes.ERR_UNKNOWN, err.cause);
  }
};

export const getErrorCode = (err: Error) => {
  return err instanceof CliftApiError
    ? err.cause
    : CliftApiErrorCodes.ERR_UNKNOWN;
};

export const getErrorReason = (err: Error) => {
  return err instanceof CliftApiError
    ? err.reason
    : CliftApiErrorCodes.ERR_UNKNOWN;
};

export const getLocalizedErrorReason = (err: Error): string => {
  if (err instanceof CliftApiError) {
    if (err.reason && i18n.exists(err.reason, { ns: "error_reasons" })) {
      // @ts-expect-error: dynamic translation keys
      return i18n.t(err.reason, { ns: "error_reasons" });
    } else if (err.cause === 403) {
      return i18n.t("not_authorized", { ns: "error_reasons" });
    }
  }

  return i18n.t("unknown_reason", { ns: "error_reasons" });
};
