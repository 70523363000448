import { ConfirmDelete } from "../../components/ConfirmDelete";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { deleteUser } from "../../services/employees-api";

export interface DeleteUserDialogProps {
  userId: number;
  setSelectedUserId: (userId: number | undefined) => void;
  onDeleteCompleted?: () => void;
}

export const DeleteUserDialog = ({
  userId,
  setSelectedUserId,
  onDeleteCompleted,
}: DeleteUserDialogProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const handleDeleteUser = async () => {
    if (userId === undefined || !cliftState.currentTenant) return;

    await deleteUser(cliftState.currentTenant.id, userId)
      .then(() => {
        onDeleteCompleted?.();
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("delete_user_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("delete_user_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });

    setSelectedUserId(undefined);
  };
  return (
    <ConfirmDelete
      title={t("delete_user_question", { ns: "dialogs" })}
      text={t("confirm_user_delete_question", {
        ns: "dialogs",
        user: userId,
      })}
      isOpen={userId !== undefined}
      onCancel={() => setSelectedUserId(undefined)}
      onConfirmed={handleDeleteUser}
    />
  );
};

export default DeleteUserDialog;
