import { useTranslation } from "react-i18next";
import { Panels } from "../context/clift-context/clift-context";
import { useCliftContext } from "../hooks/useCliftContext";

export function UserPanel() {
  const { t } = useTranslation();
  const { currentUser } = useCliftContext();

  const { cliftState } = useCliftContext();
  if ((cliftState.visiblePanels & Panels.User) == 0) return <></>;
  return (
    <div className="topPanel">
      {currentUser.clientPrincipal ? (
        <div>
          {currentUser.clientPrincipal.userDetails}
          <br />
          <a href="/.auth/logout">{t("log_out", { ns: "profile" })}</a>
        </div>
      ) : (
        <a href="/.auth/login/aadb2c">{t("log_in", { ns: "profile" })}</a>
      )}
    </div>
  );
}
