export enum StannahRole {
  ROOT_TENANT_ADMIN = "ROOT_TENANT_ADMIN",
  ROOT_TENANT_SUPPORT_AGENT = "ROOT_TENANT_SUPPORT_AGENT",

  COUNTRY_TENANT_ADMIN = "COUNTRY_TENANT_ADMIN",
  COUNTRY_TENANT_SUPPORT_AGENT = "COUNTRY_TENANT_SUPPORT_AGENT",

  LIFT_PROVIDER_ADMIN = "LIFT_PROVIDER_ADMIN",
  LIFT_PROVIDER_SUPPORT_AGENT = "LIFT_PROVIDER_SUPPORT_AGENT",
  SALES_PERSON = "SALES_PERSON",
  INSTALLER = "INSTALLER",

  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  CARER = "CARER",
}

export const rootTenantRoles = [
  StannahRole.ROOT_TENANT_ADMIN,
  StannahRole.ROOT_TENANT_SUPPORT_AGENT,
];

export const countryTenantRoles = [
  StannahRole.COUNTRY_TENANT_ADMIN,
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
];

export const liftProviderRoles = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  StannahRole.INSTALLER,
  StannahRole.SALES_PERSON,
];

export const CustomerRole = [StannahRole.ACCOUNT_OWNER];
export const DeviceRole = [StannahRole.CARER];

export const getRoleList = (tenantType: string | null | undefined) => {
  switch (tenantType) {
    case "ROOT":
      return rootTenantRoles;
    case "COUNTRY":
      return countryTenantRoles;
    case "DEALER":
      return liftProviderRoles;
    default:
      return [];
  }
};

export type DeviceRole = {
  userId: number;
  email: string;
  role: StannahRole;
};

export type UserRole = {
  userId: number;
  role: StannahRole;
  tenantId: number;
  customerId: number;
};

export type AddRoleDTO = {
  userId: number;
  role: StannahRole;
  tenantId: number;
  customerId: number;
};

export type RoleFormValues = {
  tenantId: number | undefined;
  role: StannahRole;
};

export type RoleResponse = {
  tenantId: number | undefined;
  role: StannahRole;
};

export const AddTenantAllowedRoles: string[] = [
  StannahRole.ROOT_TENANT_ADMIN,
  StannahRole.COUNTRY_TENANT_ADMIN,
  StannahRole.LIFT_PROVIDER_ADMIN,
];

export const ListTenantsAllowedRoles: string[] = [
  ...AddTenantAllowedRoles,
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
];

export const ViewTenantAllowedRoles: string[] = [...ListTenantsAllowedRoles];

export const EditTenantAllowedRoles: string[] = [
  ...AddTenantAllowedRoles,
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
];

export const DeleteTenantAllowedRoles: string[] = [...AddTenantAllowedRoles];

export const AddCustomersAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.SALES_PERSON,
  StannahRole.INSTALLER,
];

export const ListCustomersAllowedRoles: string[] = [
  ...AddCustomersAllowedRoles,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
];

export const DeleteCustomerAllowedRoles: string[] = [
  ...AddCustomersAllowedRoles,
];

export const EditCustomerAllowedRoles: string[] = [
  ...AddCustomersAllowedRoles,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
];

export const EditCustomerLiftsAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  StannahRole.INSTALLER,
];

export const ViewCustomerAllowedRoles: string[] = [
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  ...AddCustomersAllowedRoles,
];

export const ViewCustomerLiftsAllowedRoles: string[] = [
  ...ListCustomersAllowedRoles,
];

export const CustomerContactsAllowedRoles: string[] = [
  ...ViewCustomerAllowedRoles,
];

export const LiftDeleteAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
];

export const LiftSimEditAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  StannahRole.INSTALLER,
];

export const LiftSipEditAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  StannahRole.INSTALLER,
];

export const LiftPhoneNumbersEditAllowedRoles: string[] = [
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
  StannahRole.INSTALLER,
];

export const LiftSubscriptionEditAllowedRoles: string[] = [
  ...LiftPhoneNumbersEditAllowedRoles,
];

export const LiftSettingsAllowedRoles: string[] = [
  StannahRole.INSTALLER,
  ...LiftPhoneNumbersEditAllowedRoles,
];

export const LiftListAllowedRoles: string[] = [
  ...LiftSettingsAllowedRoles,
  StannahRole.ROOT_TENANT_ADMIN,
  StannahRole.COUNTRY_TENANT_ADMIN,
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
  StannahRole.LIFT_PROVIDER_SUPPORT_AGENT,
];

export const EmployeeListEditAllowedRoles: string[] = [
  StannahRole.ROOT_TENANT_ADMIN,
  StannahRole.LIFT_PROVIDER_ADMIN,
  StannahRole.COUNTRY_TENANT_ADMIN,
];

export const EmployeeListViewAllowedRoles: string[] = [
  ...EmployeeListEditAllowedRoles,
  StannahRole.COUNTRY_TENANT_SUPPORT_AGENT,
];
