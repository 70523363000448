import { ConfirmDelete } from "../../components/ConfirmDelete";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { deleteCustomer } from "../../services/customers-api";

export interface DeleteCustomerDialogProps {
  customerId: number;
  setSelectedCustomerID: (customerId: number | undefined) => void;
  afterDelete?: () => void;
}

export const DeleteCustomerDialog = ({
  customerId,
  setSelectedCustomerID,
  afterDelete,
}: DeleteCustomerDialogProps) => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const handleDeleteCustomer = async () => {
    if (customerId === undefined || !cliftState.currentTenant) return;

    await deleteCustomer(cliftState.currentTenant.id, customerId)
      .then(() => {
        afterDelete?.();
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("delete_customer_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("delete_customer_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });

    setSelectedCustomerID(undefined);
  };
  return (
    <ConfirmDelete
      title={t("delete_customer_question", { ns: "dialogs" })}
      text={t("confirm_customer_delete_question", {
        ns: "dialogs",
        customer: customerId,
      })}
      isOpen={customerId !== undefined}
      onCancel={() => setSelectedCustomerID(undefined)}
      onConfirmed={handleDeleteCustomer}
    />
  );
};

export default DeleteCustomerDialog;
