import { CliftApiError } from "./clift-api-errors";

export const parseResponse = async (res: Response) => {
  if (res.ok) {
    const body = await res.text();
    if (!body) return;

    return JSON.parse(body);
  }

  // Error handling

  if (res.status === 401) {
    window.location.replace("/.auth/logout/aadb2c");
    return;
  }

  const body = await res.json().catch(() => {
    throw new CliftApiError(res.status);
  });

  throw new CliftApiError(res.status, undefined, body.reason);
};
