import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import Page from "../../components/Page";
import PaginatedList from "../../components/PaginatedList";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useCsvExport } from "../../hooks/useCsvExport";
import { isRoleForAction } from "../../hooks/useRoles";
import {
  EmployeeListEditAllowedRoles,
  EmployeeListViewAllowedRoles,
  StannahRole,
} from "../../models/role";
import { Direction, SearchParams, Sort } from "../../models/search";
import { EmployeeListItem, EmployeeSortableColumns } from "../../models/user";
import { getErrorCode } from "../../services/clift-api-errors";
import { fetchEmployees } from "../../services/employees-api";
import DeleteUserDialog from "./DeleteUserDialog";
import { EditEmployee } from "./EditEmployee";
import { EmployeeOptionsMenu } from "./EmployeeOptionsMenu";
import { InviteEmployees } from "./InviteEmployees";

export const EmployeeList = () => {
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: EmployeeSortableColumns.ROLE,
  });
  const [totalEmployees, setTotalEmployees] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeeListItem[]>([]);
  const [selectedUserID, setSelectedUserID] = useState<number>();
  const [loading] = useState(false);
  const [fetchEmployeesTrigger, setFetchEmployeesTrigger] = useState<number>(0);

  const { cliftState, dispatchCliftState } = useCliftContext();
  const { t, i18n } = useTranslation();

  const columnHelper = createColumnHelper<EmployeeListItem>();

  const getLocalizedRole = (userRole: string): string => {
    if (i18n.exists(userRole, { ns: "role" })) {
      return i18n.t(userRole as StannahRole, { ns: "role" });
    }
    return i18n.t("NO_ROLE", { ns: "role" });
  };

  const handleSort = (column: EmployeeSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const updateEmployeeList = () => {
    setFetchEmployeesTrigger(Math.random());
  };

  const onRowClick = (info: CellContext<EmployeeListItem, string>) => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditEmployee
          userId={info.row.original.userAccountId}
          onEditCompleted={updateEmployeeList}
        />
      ),
    });
  };
  const columns: ColumnDef<EmployeeListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => (
          <div onClick={() => handleSort(EmployeeSortableColumns.NAME)}>
            {t("name", { ns: "translation" })}
            {sort.column === EmployeeSortableColumns.NAME &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => (
          <div onClick={() => onRowClick(info)}>{info.row.original.name}</div>
        ),
      }),
      columnHelper.accessor("email", {
        header: () => (
          <div onClick={() => handleSort(EmployeeSortableColumns.EMAIL)}>
            {t("email", { ns: "translation" })}
            {sort.column === EmployeeSortableColumns.EMAIL &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => (
          <div onClick={() => onRowClick(info)}>{info.row.original.email}</div>
        ),
      }),
      columnHelper.accessor("role", {
        header: () => (
          <div onClick={() => handleSort(EmployeeSortableColumns.ROLE)}>
            {t("role", { ns: "employee" })}
            {sort.column === EmployeeSortableColumns.ROLE &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) =>
          isRoleForAction(EmployeeListViewAllowedRoles) && (
            <div onClick={() => onRowClick(info)}>
              {getLocalizedRole(info.getValue())}
            </div>
          ),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          return (
            isRoleForAction(EmployeeListEditAllowedRoles) && (
              <EmployeeOptionsMenu
                userID={info.row.original.userAccountId}
                triggerUpdate={updateEmployeeList}
                setSelectedUserID={setSelectedUserID}
              />
            )
          );
        },
      }),
    ],
    [i18n.language, sort]
  );

  const handleExport = useCsvExport();

  const fetch = (search: SearchParams) => {
    if (!cliftState.currentTenant) {
      console.error("Current tenant not defined!");
      return;
    }
    return fetchEmployees(cliftState.currentTenant.id, search)
      .then((res) => {
        setTotalEmployees(res.total);
        setEmployees(res.content);
      })
      .catch((err) => {
        setEmployees([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("employees_list_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  if (!loading && cliftState.currentTenant?.id) {
    return (
      <Page
        title={
          t("employees_prefix", { ns: "employee" }) +
          cliftState.currentTenant?.name
        }
        subtitle={t("employees_subtitle", { ns: "employee" })}
        button={
          <button
            className="button"
            type="button"
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetSidePanelContent,
                content: (
                  <InviteEmployees onInviteCompleted={updateEmployeeList} />
                ),
              });
            }}
          >
            <PlusIcon />
            {t("invite_new_employee", { ns: "employee" })}
          </button>
        }
      >
        <PaginatedList<EmployeeListItem>
          columns={columns}
          data={employees}
          sort={sort}
          fetchData={fetch}
          pageUpdateTrigger={fetchEmployeesTrigger}
          totalElements={totalEmployees}
          onExport={() =>
            handleExport({
              tableName: "employees",
              tenantId: cliftState.currentTenant?.id ?? 0,
            })
          }
        />
        {selectedUserID !== undefined && (
          <DeleteUserDialog
            userId={selectedUserID}
            setSelectedUserId={setSelectedUserID}
            onDeleteCompleted={updateEmployeeList}
          />
        )}
      </Page>
    );
  } else {
    return <progress></progress>;
  }
};
