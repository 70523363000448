export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export type Sort = {
  column: string;
  direction: Direction;
};

export type SearchParams = {
  pageSize: number;
  pageNumber: number;
  search: string;
  sort: Sort | null;
};

export type Page<T> = {
  total: number;
  content: T[];
};
