import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import FormField from "../../../components/FormField";
import SideModal from "../../../components/SideModal";
import { SideModalButtonBar } from "../../../components/SideModalButtonBar";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { Device, SipSettings } from "../../../models/device";
import { getErrorCode } from "../../../services/clift-api-errors";
import { updateDevice } from "../../../services/devices-api";
import "./SipSettingsForm.css";

const DEFAULT_SIP_SETTINGS = {
  registrar: "",
  realm: "",
  username: "",
  password: "",
  phoneNumber: "",
};

interface SipSettingsFormProps {
  tenantID: number;
  device: Device;
}

export const SipSettingsForm = ({ tenantID, device }: SipSettingsFormProps) => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();
  const [settings, setSettings] = useState<SipSettings>(DEFAULT_SIP_SETTINGS);
  const [hasChanged, setChanged] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onValueChanged = (name: string, value: string | number) => {
    setChanged(true);
    setSettings({
      ...settings,
      [name]: value as string,
    });
  };

  const onClose = () => {
    if (hasChanged) {
      setCancelled(true);
    } else {
      closeSidePanel();
    }
  };

  const onSubmit = (sipSettings: SipSettings) => {
    if (!sipSettings) onClose();

    const updatedDevice = {
      ...device,
      sipSettings,
    };

    return updateDevice(tenantID, updatedDevice)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    setSettings(device.sipSettings ? device.sipSettings : DEFAULT_SIP_SETTINGS);
  }, [device.sipSettings]);

  return (
    <SideModal title={t("sip_edit_settings", { ns: "lift" })} onClose={onClose}>
      <div className="sip-settings-form">
        <form
          className="sip-settings-form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(settings);
          }}
        >
          <div className="sip-settings-form-lines">
            <FormField
              name={"registrar"}
              title={t("sip_registrar", { ns: "lift" })}
              value={settings.registrar ?? ""}
              autoComplete="off"
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"realm"}
              title={t("sip_realm", { ns: "lift" })}
              value={settings.realm ?? ""}
              autoComplete="off"
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"username"}
              title={t("sip_username", { ns: "lift" })}
              value={settings.username ?? ""}
              autoComplete="off"
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"password"}
              title={t("sip_password", { ns: "lift" })}
              value={settings.password ?? ""}
              autoComplete="off"
              onValueChanged={onValueChanged}
              type={"password"}
            />
            <FormField
              name={"phoneNumber"}
              className="formfield phone-number-field"
              title={t("sip_phone_number", { ns: "lift" })}
              value={settings.phoneNumber ?? ""}
              autoComplete="off"
              onValueChanged={onValueChanged}
            />
          </div>
          <SideModalButtonBar onCancel={onClose} showSave />
        </form>

        <ConfirmDialog
          isOpen={cancelled}
          onCancel={() => {
            setCancelled(false);
          }}
          onConfirm={() => {
            closeSidePanel();
          }}
        />
      </div>
    </SideModal>
  );
};
