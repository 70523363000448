import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { TenantListItem } from "models/tenant-list-item";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PaginatedList from "../../components/PaginatedList";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useCsvExport } from "../../hooks/useCsvExport";
import { DeviceSortableColumns } from "../../models/device";
import { DeviceListItem } from "../../models/device-list-item";
import { Direction, SearchParams, Sort } from "../../models/search";
import { getErrorCode } from "../../services/clift-api-errors";
import { CustomerDevice } from "../../services/customers-api";
import { fetchDevices } from "../../services/devices-api";
import { TenantDevice } from "../../services/tenants-api";
import "./LiftList.css";
import { LiftOptionsMenu } from "./LiftOptionsMenu";

export interface LiftListProps {
  tenant: TenantListItem;
  fetchLiftsTrigger: number;
  selectedDevice: CustomerDevice | undefined;
  setSelectedDevice(selectedDevice: CustomerDevice | undefined): void;
  setSelectedTenantDevice(selectedTenantDevice: TenantDevice | undefined): void;
  setSelectedResetDevice(selectedResetDevice: TenantDevice): void;
  updateLiftList: () => void;
}

export const LiftList = ({
  tenant,
  selectedDevice,
  fetchLiftsTrigger,
  setSelectedDevice,
  setSelectedTenantDevice,
  setSelectedResetDevice,
  updateLiftList,
}: LiftListProps) => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper<DeviceListItem>();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [devices, setDevices] = useState<DeviceListItem[]>([]);
  const [totalLifts, setTotalLifts] = useState<number>(0);
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: DeviceSortableColumns.CUSTOMER,
  });

  const getSubscriptionState = (value: string) => {
    if (value === "ACTIVE") {
      return (
        <span className="bullet-active">{t("active", { ns: "lift" })}</span>
      );
    } else if (value === "SUSPENDED") {
      return (
        <span className="bullet-paused">{t("suspended", { ns: "lift" })}</span>
      );
    } else {
      return (
        <span className="bullet-inactive">
          {t("unregistered", { ns: "lift" })}
        </span>
      );
    }
  };

  const handleSort = (column: DeviceSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const columns: ColumnDef<DeviceListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("serialNumber", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.SERIAL_NUMBER)}>
            {t("serial_number", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.SERIAL_NUMBER &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => (
          <Link
            to={info.row.original.id.toString()}
            onClick={() => {
              dispatchCliftState({
                type: CliftReducerAction.SetFromOriginalPage,
                fromOriginalPage: "/lifts",
              });
            }}
          >
            {info.row.original.serialNumber}
          </Link>
        ),
      }),
      columnHelper.accessor("model", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.MODEL)}>
            {t("model", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.MODEL &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("nickname", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.NAME)}>
            {t("nickname", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.NAME &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("subscriptionState", {
        header: () => (
          <div
            onClick={() => handleSort(DeviceSortableColumns.SUBSCRIPTION_STATE)}
          >
            {t("state", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.SUBSCRIPTION_STATE &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => getSubscriptionState(info.getValue()),
      }),
      columnHelper.accessor("customerName", {
        header: () => (
          <div onClick={() => handleSort(DeviceSortableColumns.CUSTOMER)}>
            {t("customer", { ns: "lift" })}
            {sort.column === DeviceSortableColumns.CUSTOMER &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          const device = devices.find((t) => t.id === info.row.original.id);
          return device ? (
            <LiftOptionsMenu
              customerID={device.customerId}
              deviceID={device.id}
              tenantID={tenant.id}
              selectedDevice={selectedDevice}
              onEdit={updateLiftList}
              setSelectedDevice={setSelectedDevice}
              setSelectedTenantDevice={setSelectedTenantDevice}
              setSelectedResetDevice={setSelectedResetDevice}
            />
          ) : undefined;
        },
      }),
    ],
    [i18n.language, sort, devices]
  );

  const fetchLifts = (searchParams: SearchParams) => {
    return fetchDevices(searchParams, cliftState.currentTenant?.id)
      .then((res) => {
        setTotalLifts(res.total);
        setDevices(res.content);
      })
      .catch((err) => {
        setDevices([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_list_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  const handleExport = useCsvExport();

  return (
    <PaginatedList<DeviceListItem>
      sort={sort}
      columns={columns}
      data={devices}
      totalElements={totalLifts}
      fetchData={fetchLifts}
      pageUpdateTrigger={fetchLiftsTrigger}
      onExport={() =>
        handleExport({
          tableName: "devices_full",
          tenantId: cliftState.currentTenant?.id ?? 0,
        })
      }
    />
  );
};
