import { useTranslation } from "react-i18next";
import { CustomerDevice } from "../../services/customers-api";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { TenantDevice } from "services/tenants-api";
import { isRoleForAction } from "../../hooks/useRoles";
import { LiftDeleteAllowedRoles } from "../../models/role";

export const LiftOptionsMenu = ({
  customerID,
  deviceID,
  tenantID,
  selectedDevice,
  setSelectedDevice,
  setSelectedTenantDevice,
  setSelectedResetDevice,
}: {
  customerID: number | null;
  deviceID: number;
  tenantID: number | null;
  selectedDevice?: CustomerDevice | undefined;
  setSelectedDevice(selectedDevice: CustomerDevice): void;
  setSelectedTenantDevice(selectedTenantDevice: TenantDevice): void;
  setSelectedResetDevice(selectedResetDevice: TenantDevice): void;
  onEdit?(): void;
}) => {
  const { t } = useTranslation();

  const handleRemoveDevice = async () => {
    if (customerID !== null && customerID !== undefined) {
      setSelectedDevice({ customerID, deviceID, removedFromCustomer: true });
    }
  };

  const handleRemoveTenantDevice = async () => {
    if (tenantID !== null && tenantID !== undefined) {
      setSelectedTenantDevice({ tenantID, deviceID });
    }
  };

  const handleResetDevice = async () => {
    if (tenantID !== null && tenantID !== undefined) {
      setSelectedResetDevice({ tenantID, deviceID });
    }
  };

  return (
    <ContextMenu
      items={[
        {
          id: "delete",
          title: t("remove_lift_from_customer", { ns: "lift" }),
          disabled:
            (selectedDevice?.removedFromCustomer === true &&
              deviceID === selectedDevice.deviceID) ||
            customerID === undefined,
          hidden: !isRoleForAction(LiftDeleteAllowedRoles),
          onClicked: handleRemoveDevice,
          icon: <DeleteIcon />,
        },
        {
          id: "delete",
          title: t("remove_lift_from_tenant", { ns: "lift" }),
          disabled:
            (selectedDevice?.deviceID === deviceID &&
              selectedDevice.removedFromCustomer !== true) ||
            (customerID !== undefined && selectedDevice?.deviceID !== deviceID),
          hidden: !isRoleForAction(LiftDeleteAllowedRoles),
          onClicked: handleRemoveTenantDevice,
          icon: <DeleteIcon />,
        },
        {
          id: "delete",
          title: t("factory_reset_device", { ns: "lift" }),
          hidden: !isRoleForAction(LiftDeleteAllowedRoles),
          onClicked: handleResetDevice,
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
