import { RoleFormValues, RoleResponse } from "./role";
import { TenantResponseFull } from "./tenant";

export enum EmployeeSortableColumns {
  NAME = "NAME",
  EMAIL = "EMAIL",
  ROLE = "ROLE",
}

export type User = {
  id: number;
  userId: string;
  role: string;
  name: string | null;
  email: string | null;
  internalUser: number | null;
  tenant: TenantResponseFull | null;
  customer: number | null;
};

export type EmployeeResponseFull = {
  email: string;
  roles: RoleResponse[];
};

export type InviteFormValues = {
  emails: string[];
  roles: RoleFormValues[];
};

export type EditFormValues = {
  email: string;
  roles: RoleFormValues[];
};

export type EmployeeListItem = {
  id: number;
  userAccountId: number;
  name: string | null;
  email: string | null;
  role: string | null;
};
