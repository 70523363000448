import {
  ChangeEvent,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  forwardRef,
} from "react";
import "./FormField.css";
import { useTranslation } from "react-i18next";

export interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  name: string;
  hintText?: string;
  className?: string;
  optional?: boolean;
  extensionComponent?: ReactNode;
  onValueChanged?: (name: string, value: string | number) => void;
}

const FormField = forwardRef<
  HTMLInputElement,
  PropsWithChildren<FormFieldProps>
>((props, ref) => {
  const {
    title,
    name,
    hintText,
    className,
    optional,
    extensionComponent,
    onValueChanged,
    ...rest
  } = props;

  const { t } = useTranslation();

  const handleInputChange = function (event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    onValueChanged?.(name, value);
  };

  let ariaAutoComplete = props["aria-autocomplete"];
  if (!ariaAutoComplete) {
    ariaAutoComplete = props.autoComplete === "off" ? "none" : undefined;
  }

  let list = props.list;
  if (!list) {
    list = props.autoComplete === "off" ? "autocompleteOff" : undefined;
  }

  return (
    <div className={className?.length ? className : "formfield"}>
      <label htmlFor={name}>
        {title}
        {optional && (
          <div className="formfield-optional">
            {t("optional_field", { ns: "translation" })}
          </div>
        )}
      </label>
      <div className="formfield-inputs">
        <input
          id={name}
          name={name}
          ref={ref}
          list={list}
          aria-autocomplete={ariaAutoComplete}
          type={props.type ?? "text"}
          onChange={handleInputChange}
          {...rest}
        />
        {extensionComponent && (
          <div className="formfield-extension">{extensionComponent} </div>
        )}
      </div>

      <div className="formfield-hint-text">{hintText}</div>
    </div>
  );
});

FormField.displayName = "FormField";
export default FormField;
