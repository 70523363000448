import { useEffect, useReducer, useState } from "react";
import {
  CliftActions,
  CliftContextInterface,
  CliftContextState,
  CliftReducerAction,
} from "./clift-action-types";
import { ClientPrincipal } from "../../models/client-principal";
import { useTranslation } from "react-i18next";
import { getErrorCode } from "../../services/clift-api-errors";
import { fetchTenants } from "../../services/tenants-api";
import {
  getPersistentItem,
  PersistKey,
  setPersistentItem,
} from "../../services/persistent";
import { CliftActionContext, Panels } from "./clift-context";
import { CreateTenant } from "../../app/tenants/CreateTenant";
import { AddCustomerLifts } from "../../app/customers/AddCustomerLifts";
import { fetchContacts } from "../../services/contacts-api";
import { EditContactInfo } from "../../app/lifts/details/EditContactInfo";
import { SipSettingsForm } from "../../app/lifts/details/SipSettingsForm";
import { SimSettingsForm } from "../../app/lifts/details/SimSettingsForm";
import { DeviceSettingsForm } from "../../app/lifts/details/DeviceSettingsForm";
import { AddTenantLifts } from "../../app/lifts/AddTenantLifts";

const initialState: CliftContextState = {
  currentTenant: undefined,
  tenants: undefined,
  alerts: [],
  currentCustomer: undefined,
  users: [],
  contacts: [],
  sidepanelContent: undefined,
  tenantFetch: {},
  visiblePanels: 0,
};

export const CliftContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  // const { Provider } = context;

  const [currentUser, setCurrentUser] = useState<ClientPrincipal>({
    clientPrincipal: null,
  });

  const [createTenantPanelVisible, setCreateTenantPanelVisible] =
    useState(false);
  const { t } = useTranslation();

  function cliftReducer(
    state: CliftContextState,
    action: CliftActions
  ): CliftContextState {
    switch (action.type) {
      case CliftReducerAction.FetchTenants:
        fetchTenants()
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetTenants,
              tenants: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("tenants_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
            dispatchCliftState({
              type: CliftReducerAction.SetTenants,
              tenants: [],
            });
          });

        return {
          ...state,
        };

      case CliftReducerAction.SetTenants: {
        let currentTenant = state.currentTenant;
        if (!currentTenant) {
          const savedTenantID = getPersistentItem(PersistKey.CURRENT_TENANT_ID);
          if (savedTenantID) {
            currentTenant = action.tenants?.find(
              (tenant) => tenant.id.toString() === savedTenantID
            );
          }
        }

        currentTenant = currentTenant ?? action.tenants?.[0];

        if (currentTenant) {
          setPersistentItem(
            PersistKey.CURRENT_TENANT_ID,
            currentTenant.id.toString()
          );
        }

        return {
          ...state,
          currentTenant: currentTenant,
          tenants: Object.values({
            ...Object.fromEntries(
              action.tenants?.map((tenant) => [tenant.id, tenant]) ?? []
            ),
          }),
        };
      }
      case CliftReducerAction.SetCurrentTenant:
        return {
          ...state,
          currentTenant: action.tenant,
        };
      case CliftReducerAction.AddAlert:
        return {
          ...state,
          alerts: [...state.alerts, action.alert],
        };

      case CliftReducerAction.SetSidePanelContent:
        return {
          ...state,
          sidepanelContent: action.content,
        };

      case CliftReducerAction.AlertPanelVisible:
        return {
          ...state,
          visiblePanels: action.visible
            ? Panels.Alerts
            : state.visiblePanels & ~Panels.Alerts,
        };
      case CliftReducerAction.UserPanelVisible:
        return {
          ...state,
          visiblePanels: action.visible
            ? Panels.User
            : state.visiblePanels & ~Panels.User,
        };
      case CliftReducerAction.AddTenant:
        return {
          ...state,
          sidepanelContent: <CreateTenant />,
        };

      case CliftReducerAction.SetCurrentCustomer:
        return {
          ...state,
          currentCustomer: action.customer,
        };
      case CliftReducerAction.AddCustomerLifts:
        return {
          ...state,
          sidepanelContent: action.customer ? (
            <AddCustomerLifts editingCustomer={action.customer} />
          ) : undefined,
        };
      case CliftReducerAction.AddTenantLifts:
        return {
          ...state,
          sidepanelContent: <AddTenantLifts tenantID={action.tenantID} />,
        };
      case CliftReducerAction.FetchContacts:
        fetchContacts(action.tenantID!, action.customerID)
          .then((res) => {
            dispatchCliftState({
              type: CliftReducerAction.SetContacts,
              contacts: res,
            });
          })
          .catch((err) => {
            dispatchCliftState({
              type: CliftReducerAction.AddAlert,
              alert: t("contacts_list_http_fail", {
                ns: "alerts",
                code: getErrorCode(err),
              }),
            });
          });

        return {
          ...state,
        };

      case CliftReducerAction.SetContacts:
        return {
          ...state,
          contacts: Object.values({
            ...Object.fromEntries(
              action.contacts.map((contact) => [contact.id, contact])
            ),
          }),
        };
      case CliftReducerAction.AddUsers:
        return {
          ...state,
          // Override users with same id
          users: Object.values({
            ...Object.fromEntries(state.users.map((user) => [user.id, user])),
            ...Object.fromEntries(action.users.map((user) => [user.id, user])),
          }),
        };
      case CliftReducerAction.CloseSidePanel:
        return {
          ...state,
          sidepanelContent: undefined,
        };
      case CliftReducerAction.EditLiftPhoneNumbers:
        return {
          ...state,
          sidepanelContent: (
            <EditContactInfo
              deviceId={action.deviceId}
              initialContactInfo={action.contactInfo}
            />
          ),
        };
      case CliftReducerAction.EditSipSettings:
        return {
          ...state,
          sidepanelContent: (
            <SipSettingsForm
              tenantID={action.tenantID}
              device={action.device}
            />
          ),
        };
      case CliftReducerAction.EditSimSettings:
        return {
          ...state,
          sidepanelContent: (
            <SimSettingsForm
              tenantID={action.tenantID}
              device={action.device}
            />
          ),
        };
      case CliftReducerAction.EditDeviceSettings:
        return {
          ...state,
          sidepanelContent: (
            <DeviceSettingsForm
              tenantID={action.tenantID}
              device={action.device}
            />
          ),
        };
      case CliftReducerAction.SetFromOriginalPage:
        return {
          ...state,
          fromOriginalPage: action.fromOriginalPage,
        };
      default:
        return { ...state };
    }
  }

  const [cliftState, dispatchCliftState] = useReducer(
    cliftReducer,
    initialState
  );

  useEffect(() => {
    fetch("/.auth/me")
      .then(async (response) => {
        const currentUser: ClientPrincipal | undefined = await response
          .json()
          .catch((error) => {
            // Handle errors here
            console.error(error);
          });
        if (currentUser) setCurrentUser(currentUser);
      })
      .catch((err) => {
        //TODO: proper error handling
        console.error(err);
      });
  }, []);

  const contextValue: CliftContextInterface = {
    currentUser,
    createTenantPanelVisible,
    setCreateTenantPanelVisible,
    cliftState,
    dispatchCliftState,
  };

  return (
    <CliftActionContext.Provider value={contextValue}>
      {children}
    </CliftActionContext.Provider>
  );
};
