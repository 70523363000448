import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "react-datetime-picker";
import { toast } from "react-toast";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import FormField from "../../../components/FormField";
import { Option, Picker } from "../../../components/Picker";
import SideModal from "../../../components/SideModal";
import { ToggleSwitch } from "../../../components/ToggleSwitch";
import { SideModalButtonBar } from "../../../components/SideModalButtonBar";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import {
  Device,
  DeviceSettings,
  DeviceUILanguage,
  SubscriptionState,
} from "../../../models/device";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../../services/clift-api-errors";
import {
  editDeviceSettings,
  EditDeviceSettingsRequest,
} from "../../../services/device-settings-api";
import "./DeviceSettingsForm.css";

const DEFAULT_SETTINGS = {
  id: 0,
  subscriptionStartDate: new Date("2024-10-20").toUTCString(),
  subscriptionEndDate: new Date("2025-10-20").toUTCString(),
  subscriptionState: SubscriptionState.UNREGISTERED,
  deviceUiLanguage: DeviceUILanguage.ENGLISH,
  liftGuiTelemetryEnabled: true,
  liftEcuUpdateInterval: 1000,
  batteryVoltageReadInterval: 1000,
  networkStateTelemetryEnabled: true,
  networkStateUpdateInterval: 1000,
  runtimeInfoUpdateInterval: 1000,
  runtimeInfoTelemetryEnabled: true,
  temperatureUpdateInterval: 1000,
  temperatureTelemetryEnabled: true,
};

interface DeviceSettingsFormProps {
  tenantID: number;
  device: Device;
}

export const DeviceSettingsForm = ({
  tenantID,
  device,
}: DeviceSettingsFormProps) => {
  const { t } = useTranslation();
  const { dispatchCliftState } = useCliftContext();
  const [settings, setSettings] = useState<DeviceSettings>(DEFAULT_SETTINGS);
  const [hasChanged, setChanged] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onValueChanged = (
    name: string,
    value: string | number | boolean | Date | null
  ) => {
    setChanged(true);
    setSettings({
      ...settings,
      [name]: value as string,
    });
  };

  const onClose = () => {
    if (hasChanged) {
      setCancelled(true);
    } else {
      closeSidePanel();
    }
  };

  const onSubmit = (deviceSettings: DeviceSettings) => {
    if (!deviceSettings) onClose();
    const requestBody = { ...deviceSettings } as EditDeviceSettingsRequest;
    return editDeviceSettings(tenantID, device.id, requestBody)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("lift_edit_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  useEffect(() => {
    setSettings(
      device.deviceSettings ? device.deviceSettings : DEFAULT_SETTINGS
    );
  }, [device.deviceSettings]);

  return (
    <SideModal
      title={t("device_edit_settings", { ns: "lift" })}
      onClose={onClose}
    >
      <div className="device-settings-form">
        <form
          className="device-settings-form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(settings);
          }}
        >
          <div className="device-settings-form-lines">
            <h2>{t("subscription_validity", { ns: "lift" })}</h2>
            <DateTimePicker
              onChange={(date: Date | null) => {
                onValueChanged("subscriptionStartDate", date);
              }}
              value={settings.subscriptionStartDate}
            />
            <DateTimePicker
              onChange={(date: Date | null) => {
                onValueChanged("subscriptionEndDate", date);
              }}
              value={settings.subscriptionEndDate}
            />

            <Picker
              noCurrentText={t("select_contact", { ns: "lift" })}
              noOptionsText={t("no_contacts_to_select", { ns: "lift" })}
              title={t("device_ui_language", { ns: "lift" })}
              items={Object.values(DeviceUILanguage).map((state) => {
                return {
                  label: state.toString(),
                  value: state,
                } as Option<DeviceUILanguage>;
              })}
              currentValue={settings.deviceUiLanguage}
              onSelect={(lang: DeviceUILanguage) => {
                onValueChanged("deviceUiLanguage", lang);
              }}
            />

            <div className="device_settings_toggle_switch">
              <ToggleSwitch
                label={t("lift_gui_telemetry_enabled", { ns: "lift" })}
                on={settings.liftGuiTelemetryEnabled}
                onChange={(on) => onValueChanged("liftGuiTelemetryEnabled", on)}
              />
            </div>

            <FormField
              name={"liftEcuUpdateInterval"}
              title={t("lift_ecu_update_interval", { ns: "lift" })}
              type="number"
              min={1}
              value={settings.liftEcuUpdateInterval ?? ""}
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"batteryVoltageReadInterval"}
              title={t("battery_voltage_read_interval", { ns: "lift" })}
              type="number"
              min={1}
              value={settings.batteryVoltageReadInterval ?? ""}
              onValueChanged={onValueChanged}
            />
            <FormField
              name={"networkStateUpdateInterval"}
              title={t("network_state_update_interval", { ns: "lift" })}
              type="number"
              min={1}
              value={settings.networkStateUpdateInterval ?? ""}
              onValueChanged={onValueChanged}
              extensionComponent={
                <ToggleSwitch
                  on={settings.networkStateTelemetryEnabled}
                  onChange={(on) =>
                    onValueChanged("networkStateTelemetryEnabled", on)
                  }
                />
              }
            />
            <FormField
              name={"runtimeInfoUpdateInterval"}
              title={t("runtime_info_update_interval", { ns: "lift" })}
              type="number"
              min={1}
              value={settings.runtimeInfoUpdateInterval ?? ""}
              onValueChanged={onValueChanged}
              extensionComponent={
                <ToggleSwitch
                  on={settings.runtimeInfoTelemetryEnabled}
                  onChange={(on) =>
                    onValueChanged("runtimeInfoTelemetryEnabled", on)
                  }
                />
              }
            />
            <FormField
              name={"temperatureUpdateInterval"}
              title={t("temperature_update_interval", { ns: "lift" })}
              type="number"
              value={settings.temperatureUpdateInterval ?? ""}
              min={1}
              onValueChanged={onValueChanged}
              extensionComponent={
                <ToggleSwitch
                  on={settings.temperatureTelemetryEnabled}
                  onChange={(on) =>
                    onValueChanged("temperatureTelemetryEnabled", on)
                  }
                />
              }
            />
          </div>
          <SideModalButtonBar onCancel={onClose} showSave />
        </form>

        <ConfirmDialog
          isOpen={cancelled}
          onCancel={() => {
            setCancelled(false);
          }}
          onConfirm={() => {
            closeSidePanel();
          }}
        />
      </div>
    </SideModal>
  );
};
