import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toast";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { inviteEmployees } from "../../services/employees-api";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { RoleFormValues } from "../../models/role";
import SideModal from "../../components/SideModal";
import { InviteEmployeesForm } from "./InviteEmployeesForm";

export interface InviteEmployeesProps {
  onInviteCompleted?: () => void;
}

export const InviteEmployees = ({
  onInviteCompleted,
}: InviteEmployeesProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  if (loading) return <progress></progress>;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onInviteEmployees = (emails: string[], roles: RoleFormValues[]) => {
    if (!cliftState.currentTenant) throw new Error("No current tenant");

    setLoading(true);

    return inviteEmployees(cliftState.currentTenant.id, emails, roles)
      .then(() => {
        setLoading(false);
        closeSidePanel();
        onInviteCompleted?.();
        toast.success(
          t("invite_successfull", { ns: "employee", users: emails.join(", ") })
        );
      })
      .catch((err: Error) => {
        setLoading(false);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("invite_employee_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
        toast.error(
          t("error") +
            t("delete_customer_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={t("invite_new_employee", { ns: "employee" })}
      pageCount={1}
      onClose={closeSidePanel}
    >
      <InviteEmployeesForm
        onSubmit={onInviteEmployees}
        onClose={closeSidePanel}
      />
    </SideModal>
  );
};
