import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import Page from "../../components/Page";
import PaginatedList from "../../components/PaginatedList";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../hooks/useCliftContext";
import { useCsvExport } from "../../hooks/useCsvExport";
import { isRoleForAction } from "../../hooks/useRoles";
import { CustomerSortableColumns } from "../../models/customer";
import { CustomerListItem } from "../../models/customer-list-item";
import {
  AddCustomersAllowedRoles,
  EditCustomerAllowedRoles,
  ViewCustomerAllowedRoles,
} from "../../models/role";
import { Direction, SearchParams, Sort } from "../../models/search";
import { getErrorCode } from "../../services/clift-api-errors";
import { fetchCustomers } from "../../services/customers-api";
import { CreateCustomer } from "./CreateCustomer";
import { CustomerOptionsMenu } from "./CustomerOptionsMenu";
import DeleteCustomerDialog from "./DeleteCustomerDialog";

export const CustomerList = () => {
  const { t, i18n } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const columnHelper = createColumnHelper<CustomerListItem>();
  const isAddCustomersAllowed: boolean = isRoleForAction(
    AddCustomersAllowedRoles
  );

  const [loading] = useState(false);
  const [toBeDeletedCustomer, setToBeDeletedCustomer] = useState<number>();
  const [fetchCustomersTrigger, setFetchCustomersTrigger] = useState<number>(0);
  const [customers, setCustomers] = useState<CustomerListItem[]>([]);
  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const [sort, setSort] = useState<Sort>({
    direction: Direction.ASC,
    column: CustomerSortableColumns.NAME,
  });

  const handleSort = (column: CustomerSortableColumns) => {
    setSort((prevSort) => ({
      column,
      direction:
        prevSort.column === column && prevSort.direction === Direction.ASC
          ? Direction.DESC
          : Direction.ASC,
    }));
  };

  const handleExport = useCsvExport();

  const updateCustomerList = () => setFetchCustomersTrigger(Math.random());
  const columns: ColumnDef<CustomerListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => (
          <div onClick={() => handleSort(CustomerSortableColumns.NAME)}>
            {t("name", { ns: "translation" })}
            {sort.column === CustomerSortableColumns.NAME &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) =>
          isRoleForAction(ViewCustomerAllowedRoles) ? (
            <Link
              to={info.row.original.id.toString()}
              onClick={() => {
                dispatchCliftState({
                  type: CliftReducerAction.SetFromOriginalPage,
                  fromOriginalPage: "/customers",
                });
              }}
            >
              {info.row.original.name}
            </Link>
          ) : (
            <>{info.row.original.name}</>
          ),
      }),
      columnHelper.accessor("organization", {
        header: () => (
          <div onClick={() => handleSort(CustomerSortableColumns.ORGANIZATION)}>
            {t("organization", { ns: "customer" })}
            {sort.column === CustomerSortableColumns.ORGANIZATION &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.lifts + "", {
        id: "lifts",
        header: () => (
          <div onClick={() => handleSort(CustomerSortableColumns.LIFTS)}>
            {t("lifts", { ns: "customer" })}
            {sort.column === CustomerSortableColumns.LIFTS &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
      }),
      columnHelper.accessor("city", {
        header: () => (
          <div onClick={() => handleSort(CustomerSortableColumns.CITY)}>
            {t("city", { ns: "customer" })}
            {sort.column === CustomerSortableColumns.CITY &&
              (sort.direction === Direction.ASC ? " ▲" : " ▼")}
          </div>
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          const customer = customers.find((t) => t.id === info.row.original.id);
          return customer
            ? isRoleForAction(EditCustomerAllowedRoles) && (
                <CustomerOptionsMenu
                  customerID={customer.id}
                  triggerUpdate={updateCustomerList}
                  setSelectedCustomerID={setToBeDeletedCustomer}
                />
              )
            : undefined;
        },
      }),
    ],
    [i18n.language, customers, sort]
  );

  const fetchData = (searchParams: SearchParams) => {
    if (!cliftState.currentTenant?.id) {
      return;
    }
    return fetchCustomers(searchParams, cliftState.currentTenant?.id)
      .then((res) => {
        setTotalCustomers(res.total);
        setCustomers(res.content);
      })
      .catch((err) => {
        setCustomers([]);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("customer_list_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  if (!loading && cliftState.currentTenant) {
    return (
      <Page
        title={t("customers", { ns: "customer" })}
        subtitle={t("customers_subtitle", { ns: "customer" })}
        button={
          isAddCustomersAllowed && (
            <button
              className="button"
              type="button"
              onClick={() => {
                dispatchCliftState({
                  type: CliftReducerAction.SetSidePanelContent,
                  content: (
                    <CreateCustomer onCreateCompleted={updateCustomerList} />
                  ),
                });
              }}
            >
              <PlusIcon />
              {t("add_new_customer", { ns: "customer" })}
            </button>
          )
        }
      >
        <PaginatedList<CustomerListItem>
          sort={sort}
          fetchData={fetchData}
          columns={columns}
          data={customers}
          pageUpdateTrigger={fetchCustomersTrigger}
          totalElements={totalCustomers}
          onExport={() =>
            handleExport({
              tableName: "customers",
              tenantId: cliftState.currentTenant?.id ?? -1,
            })
          }
        />
        {toBeDeletedCustomer !== undefined && (
          <DeleteCustomerDialog
            customerId={toBeDeletedCustomer}
            afterDelete={updateCustomerList}
            setSelectedCustomerID={(value: number | undefined) => {
              setToBeDeletedCustomer(value);
            }}
          />
        )}
      </Page>
    );
  } else {
    return <progress></progress>;
  }
};
